@import '/Users/owner/Desktop/Projects/apjportfolio/src/IMGS/dean-rose-602672-unsplash.jpg';

#aboutMe {
    height: 600px;
    font-family:  'Merriweather', serif;
    background-image: url(../../../src/IMGS/dean-rose-602672-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
}
#divide{
    background-color: white;
}
#profilePhoto {
    height: 120px;
    width: 120px;
    border-radius: 50%;
}
